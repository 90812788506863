<template>
  <v-row
    v-if="attachment.length > 0"
    justify="center"
  >
    <v-col cols="8">
      <div style="padding-bottom: 10px">
        <span> {{ attachment.length }} Anexo(s) </span>
      </div>

      <v-row>
        <div
          v-for="item in attachment"
          :key="item.id"
          class="d-flex"
          style="padding: 10px"
        >
          <v-btn
            target="_blank"
            :href="item.link"
            color="white"
          >
            <v-icon color="secondary">
              mdi-file-document-outline
            </v-icon>
            <span>{{ getNameUrl(item.link) }}</span>
          </v-btn>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AttachmentCards',
  props: {
    attachment: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getNameUrl(url) {
      const decodedURL = decodeURI(url)
      const nameFormatted = decodedURL.split('/')
      return nameFormatted[3]
    }
  }
}
</script>
